import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'ri-resolution-ui-callback',
  standalone: true,
  templateUrl: './callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallbackComponent {
  isAuthenticated$ = this.authService.isAuthenticated$;
  constructor(private authService: AuthService) {} //need to make sure AuthService is imported so the redirect actually happens.
}
