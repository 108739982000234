import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RouteObserver } from 'src/app/shared/services/route-observer/route-observer.service';
import { UrlParamsState } from './url-params.state';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsEffect {
  constructor(
    private readonly routeObserver: RouteObserver,
    private readonly urlParamsState: UrlParamsState
  ) {}

  public registerUrlParamsTaskIdChanges(): void {
    this.routeObserver.register({
      path: '/tasks/:taskid',
      callback: (node: ActivatedRoute) => {
        node.params.pipe(take(1)).subscribe((params: Params) => {
          if (params['taskid']) {
            this.urlParamsState.setActiveTaskId(params['taskid']);
          } else {
            this.urlParamsState.setActiveTaskId(null);
          }
        });
      },
    });
  }
}
