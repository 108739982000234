<div (mouseleave)="onContainerMouseLeave()">
  <p-menubar
    #menubarRef
    [autoDisplay]="true"
    [autoHide]="true"
    [model]="primeNgMenuItems()"
    styleClass="border-none p-0 text-section-header px-3 gap-5 h-14"
  >
    <ng-template #start>
      <div>
        <a href="/">
          <img
            src="../../../../../../assets/R1_logo.svg"
            alt="R1 RCM"
            width="80"
            height="47"
          />
        </a>
      </div>
    </ng-template>
    <ng-template #end>
      @if (userInfo()?.fullName) {
        <div
          class="text-section-header inline-block h-10 w-10 cursor-pointer content-center justify-self-end rounded-full bg-white text-center text-r1-blue-800"
          (mouseenter)="overlayPanelRef.toggle($event)"
        >
          {{ userInfo()!.fullName! | firstLetters | uppercase }}
        </div>
      }

      <p-popover #overlayPanelRef>
        <ng-template #content>
          <div (mouseleave)="onUserProfileOverlayMouseLeave()" class="w-72">
            <ri-resolution-ui-user-profile
              [userProfileInfo]="userInfo()"
              (logout)="onLogout()"
            ></ri-resolution-ui-user-profile>
          </div>
        </ng-template>
      </p-popover>
    </ng-template>
  </p-menubar>
</div>
