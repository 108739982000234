import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';

export interface UserProfileInfo {
  fullName?: string;
  email?: string;
}

@Component({
  selector: 'ri-resolution-ui-user-profile',
  imports: [ButtonModule],
  templateUrl: './user-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  readonly userProfileInfo = input<UserProfileInfo | null>();

  readonly logout = output<void>();

  onLogout() {
    this.logout.emit();
  }
}
