import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GlobalToastComponent } from './shared/components/global-toast/global-toast.component';
import { AppHeaderComponent } from './shared/components/app-header/app-header.component';
import { RouteObserver } from './shared/services/route-observer/route-observer.service';
import { TasksWorkerTodoListEffect } from './routes/tasks-worker/shared/state/todo-list/tasks-worker-todo-list.effect';
import { TasksWorkerCompletedListEffect } from './routes/tasks-worker/shared/state/completed-list/tasks-worker-completed-list.effect';
import { InsightsService } from './shared/services/insights/insights.service';
import { AllTasksEffect } from './routes/tasks/shared/state/all-tasks/all-tasks.effect';
import { FeatureFlagsService } from 'src/app/shared/services/feature-flags/feature-flags.service';
import { NetworkAvailabilityDialogComponent } from './shared/features/network-availability-dialog/dialog/network-availability-dialog.component';
import { NetworkAvailabilityHandlerService } from './shared/features/network-availability-dialog/services/network-availability-handler.service';
import { ApiNames } from './shared/features/network-availability-dialog/settings/settings';
import { AuthService, User } from '@auth0/auth0-angular';
import { UrlParamsEffect } from './routes/tasks-worker/shared/state/url-params/url-params.effect';
import { PermissionsService } from '@cloudmed/ui';

@Component({
  imports: [
    RouterOutlet,
    GlobalToastComponent,
    AppHeaderComponent,
    NetworkAvailabilityDialogComponent,
  ],
  selector: 'ri-resolution-ui-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly routeObserver: RouteObserver,
    private readonly tasksWorkerTodoListEffect: TasksWorkerTodoListEffect,
    private readonly tasksWorkerCompletedListEffect: TasksWorkerCompletedListEffect,
    private readonly allTasksEffect: AllTasksEffect,
    // InsightsService should be instantiated even if it is not used directly
    private readonly insightsService: InsightsService,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly networkAvailabilityHandlerService: NetworkAvailabilityHandlerService,
    private readonly authService: AuthService,
    private readonly urlParamsEffect: UrlParamsEffect,
    private readonly permissionsService: PermissionsService
  ) {
    this.authService.user$.subscribe((user: User | null | undefined) => {
      if (user?.email) {
        this.insightsService.setAuthenticatedUserContext(user.email);
      } else {
        this.insightsService.clearAuthenticatedUserContext();
      }

      // App component is not routable component. So it is not protected by
      // AuthGuard from Auth0 lib. It means that we shouldn't run any API
      // requests until user will be authenticated. Otherwise we will
      // initiate API requests (to fill UI states with data), but those
      // requests fail with 401 error. It is happening almost instantly,
      // just flash for a 1 second. But during this short period of time
      // there are multiple requests are already sent to API, then
      // Auth0 finds that user is not authenticated and uses "redirect_uri"
      // to navigate to another page. Even though API requests disappear
      // from Network tab of the Dev Tools, basically they are not gone
      // on the browser level if they were already sent. Besides that
      // server side may recieve them.
      if (!user) {
        return;
      }
      this.permissionsService.loadPermissions();

      this.networkAvailabilityHandlerService.checkAvailability([
        ApiNames.MechanicalTurk,
        ApiNames.AccountLink,
        ApiNames.ResolutionStore,
      ]);

      // Load tasks
      this.tasksWorkerTodoListEffect.loadTasks();
      this.tasksWorkerCompletedListEffect.loadTasks();
      this.allTasksEffect.loadTasks();

      // update activeTaskId state on url change.
      this.urlParamsEffect.registerUrlParamsTaskIdChanges();

      // start listening angular router navigations
      this.routeObserver.observe();

      this.featureFlagsService.start();
    });
  }
}
