import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { GLOBAL_TOASTER_KEY } from '../../models/global.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ri-resolution-ui-global-toast',
  imports: [ToastModule],
  templateUrl: './global-toast.component.html',
})
export class GlobalToastComponent {
  readonly GLOBAL_TOASTER_KEY = GLOBAL_TOASTER_KEY;
}
