import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../../features/header/components/header/header.component';

@Component({
  selector: 'ri-resolution-ui-app-header',
  imports: [HeaderComponent],
  templateUrl: './app-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {}
