import { HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

export interface R1MenuItem {
  icon: string | null;
  label: string;
  sidebarLabel: string | null;
  url: string | null;
  items: R1MenuItem[];
}

type R1MenuItemsResponse = R1MenuItem[];

interface ServiceParameters {
  apiUrl: string;
  params?: {
    [param: string]:
      | string
      | number
      | boolean
      | ReadonlyArray<string | number | boolean>;
  };
}

export const ApiParametersToken = new InjectionToken<ServiceParameters>('');

@Injectable({ providedIn: 'root' })
export class MenuItemsApiService {
  // Dependencies
  private readonly httpClient = inject(HttpClient);
  private readonly apiParametersToken = inject(ApiParametersToken);

  // Menu items
  readonly menuItems = toSignal<R1MenuItemsResponse>(this.getMenuItems());

  private getMenuItems(): Observable<R1MenuItemsResponse> {
    return this.httpClient.get<R1MenuItemsResponse>(
      this.apiParametersToken.apiUrl,
      !!this.apiParametersToken.params
        ? { params: this.apiParametersToken.params }
        : {}
    );
  }
}
