import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetters',
  standalone: true,
})
export class FirstLettersPipe implements PipeTransform {
  transform(value: string) {
    const words = value.split(' ');
    const firstLetters = words.map((word) => word.charAt(0));
    return firstLetters.join('');
  }
}
