import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  signal,
  untracked,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { NetworkAvailabilityHandlerService } from '../services/network-availability-handler.service';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { AuthService, User } from '@auth0/auth0-angular';
import { take } from 'rxjs';
import { AppConfigService } from 'src/app/shared/app-config/app-config.service';

@Component({
  selector: 'ri-resolution-ui-network-availability-dialog',
  imports: [DialogModule, JsonPipe],
  templateUrl: './network-availability-dialog.component.html',
  styleUrl: './network-availability-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkAvailabilityDialogComponent {
  readonly visible = signal<boolean>(false);

  readonly userData = signal<User | null>(null);
  readonly userAgent = this.document.defaultView?.navigator.userAgent || '';
  readonly utcDate = signal<string>('');
  readonly reportIssueUrl = this.appConfigService.config.reportIssueUrl;

  readonly errors = this.networkAvailabilityHandlerService.errorSignal;

  private readonly hasError = computed(() => {
    return this.errors().length > 0;
  });

  constructor(
    private readonly networkAvailabilityHandlerService: NetworkAvailabilityHandlerService,
    private readonly authService: AuthService,
    private readonly appConfigService: AppConfigService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.trackApiAvailabilityError();
  }

  private trackApiAvailabilityError(): void {
    effect(() => {
      const hasError = this.hasError();

      untracked(() => {
        this.authService.user$.pipe(take(1)).subscribe((user) => {
          this.userData.set(user || null);
        });

        this.utcDate.set(new Date().toUTCString());
        this.visible.set(hasError);
      });
    });
  }
}
