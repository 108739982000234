import {
  ChangeDetectionStrategy,
  Component,
  input,
  effect,
} from '@angular/core';
import { FilesService } from '../../shared/services/files-service/files.service';

@Component({
  selector: 'ri-resolution-ui-files-download',
  imports: [],
  templateUrl: './files-download.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesDownloadComponent {
  public readonly resolverFileId = input.required<string>();

  constructor(private readonly filesService: FilesService) {
    effect(() => {
      this.filesService.previewFileById(this.resolverFileId());
    });
  }
}
